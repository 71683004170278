import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Child } from '../child';
import { ChildrenService } from '../children.service';
import { THEME } from '../config';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'app-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.css']
})
export class ChildrenComponent implements OnInit {
  theme: string = THEME;
  child_list: Child[];
  new_child: Child = new Child();

  constructor(private children: ChildrenService,
              private router: Router,
              private notification: NotificationService) { }

  ngOnInit() {
    this.children.getChildren()
      .subscribe( res => this.child_list = res.children );
    this.new_child.name = '';
    this.new_child.gender = 'boy';
  }
  
  viewBooks( child: Child ) {
    this.children.setChild(child);
    this.router.navigate(['/explore']);
  }
  
  viewStories( child: Child ) {
    this.children.setChild(child);
    this.router.navigate(['/my-stories']);
  }

  addChild() {
    if(this.new_child.name.trim().length == 0) {
      this.notification.add('No name error', 'Please give a name for your child');
      return;
    }
    this.children.add(this.new_child)
      .subscribe( res => {
                    if( res.children.length > 0 ) {
                      this.child_list.push(res.children[0]);
                    }
      });
  }
  
  removeChild(child: Child) {
    
  }

}
