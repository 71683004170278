import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NotificationService } from './notification.service';
import { catchError, map } from 'rxjs/operators';
import { Book, Story, BookParameter } from './book';
import { User } from './user';
import { Child } from './child';

export const API_ROOT = 'https://www.storymill.co.uk/services/index.php';

@Injectable({
  providedIn: 'root'
})
export class StorybookHttp {
  books: Observable<Book>
  
  constructor(private http: HttpClient,
              private notifications: NotificationService) {}
  
  createBook( json ) {
    let book = new Book();
    book.book_id    = json.id;
    book.title      = json.attributes.title;
    book.thumbnail  = json.attributes.thumbnail;
    book.author     = json.attributes.author;
    book.rating     = json.attributes.rating;
    book.categories = json.attributes.categories;
    book.synopsis   = json.attributes.synopsis;
    book.duration   = json.attributes.duration;
    
    book.parameters = new Array<BookParameter>();
    for(let i = 0; i < json.attributes.parameters.attributes.length; i++ ) {
      let p = new BookParameter();
      p.name              = json.attributes.parameters.attributes[i].name;
      p.caption           = json.attributes.parameters.attributes[i].caption;
      p.meta              = json.attributes.parameters.attributes[i].meta;

      book.parameters.push(p);
    }

    return book;
  }

  createStory( json ) {
    let story = new Story();
    story.story_id  = json.id;
    story.title     = json.attributes.title;
    story.thumbnail = json.attributes.thumbnail;
    story.author    = json.attributes.author;
    story.synopsis  = json.attributes.synopsis;
    story.story     = json.attributes.story;
    return story;
  }
  
  createUser( json ) {
    let user = new User();
    user.user_id       = json.id;
    user.name          = json.attributes.name;
    user.email_address = json.attributes.email_addres;
    return user;
  }
  
  createChild( json ) {
    let child = new Child();
    child.child_id = json.id;
    child.name = json.attributes.name;
    child.gender = json.attributes.gender;
    if( typeof json.attributes.age === 'undefined' ) {
      child.age = 0;
    }
    else {
      child.age = json.attributes.age;
    }
    return child;
  }
  
  processResponse( response ) {
    let books    = new Array<Book>();
    let stories  = new Array<Story>();
    let users    = new Array<User>();
    let children = new Array<Child>();

    if( typeof response.data == 'object' ) {
      
      let data = new Array();
      
      if( typeof response.data.length == 'undefined' ) {
        data.push( response.data );
      }
      else {
        data = response.data;
      }
      
      for( let i = 0; i < data.length; i++ ) {
        if( data[i].type == 'book' ) {
          books.push( this.createBook( data[i] ) );
        }
        else if( data[i].type == 'story' ) {
          stories.push( this.createStory( data[i] ) );
        }
        else if( data[i].type == 'user' ) {
          users.push( this.createUser( data[i] ) );
        }
        else if( data[i].type == 'child' ) {
          children.push( this.createChild( data[i] ) );
        }
      }
    }
    else if( typeof response.errors == 'object' ) {
      for( let i = 0; i < response.errors.length; i++ ) {
        this.notifications.add( response.errors[i].title, response.errors[i].detail );
      }
      return [];
    }
    else if( (typeof response == 'object') && !response.length) {
      // do nothing
    }
    else {
      this.notifications.add( "Response from server not recognised", "The server replied to my request but I did not understand the response." );
    }
    
    if( typeof response.included == 'object' ) {
      for( let j = 0; j < response.included.length; j++ ) {
        if( response.included[j].type == 'child' ) {
          children.push( this.createChild( response.included[j] ) );
        }
      }
    }
    
    let answer = {  books: books,
                    stories: stories,
                    users: users,
                    children: children };
    return answer;
  }
 
  get( url ): Observable<any> {
    return this.http.get(url, { withCredentials: true } ).pipe(
      map( x => {
        x = this.processResponse(x);
        return x;
      }),
      catchError(error => {
        this.processResponse(error.error)
        return [];
      })
    );
  }
  
  post( url, payload ): Observable<any> {
    return this.http.post(url, payload, { withCredentials: true } ).pipe(
      map( x => this.processResponse(x) ),
      catchError(error => {
        this.processResponse(error.error)
        return [];
      })
    );
  }
  
}