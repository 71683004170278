import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { User } from './user';
import { Child } from './child';
import { StorybookHttp, API_ROOT } from './restapi';


const NULL_USER = {
  user_id      : 0,
  name         : '',
  email_address: '',
  password     : '',
  status       : 'Unvalidated'
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  initial_user_check_complete: boolean = false;
  details: User = NULL_USER;

  constructor(private http: StorybookHttp,
              private router: Router) {
    this.http.get(API_ROOT + "/accounts")
           .subscribe(r => {
                        if(r.users.length) {
                          this.details = r.users[0];
                        }
                        this.initial_user_check_complete = true; 
                      });
  }
  
    
  loggedIn(): boolean {
    return this.details.user_id > 0;
  }
  
  login(email: string, password: string): Observable<any> {
    return this.http.post(API_ROOT + "/login", { email_address: email, password: password } );
  }
  
  logout() {
    this.details = NULL_USER;
    return this.http.post(API_ROOT + "/logout", {});
  }
  
  register(name, email, password, child: Child): Observable<any> {
    return this.http.post(
        API_ROOT + "/accounts",
        { account_details: { name: name, email_address: email, password: password },
          child_details:   { name: child.name, gender: child.gender } }
    );
  }
}
