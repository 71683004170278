import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { ChildrenService } from '../children.service';
import { THEME } from '../config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  theme: string = THEME;
  email: string = '';
  password: string = '';
  error: string = '';

  constructor(private user: UserService,
              private children: ChildrenService,
              private router: Router) { }

  ngOnInit() {
  }
  
  login() {
    this.user.login(this.email, this.password).
      subscribe( res => {
        this.user.details = res.users[0];
        this.router.navigate(['/children']);  
      });
  }

}
