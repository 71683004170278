import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Story, Book, BookParameter } from './book';
import { Child } from './child';
import { BookService } from './book.service';
import { ChildrenService } from './children.service';
import { StorybookHttp, API_ROOT } from './restapi';

@Injectable({
  providedIn: 'root'
})
export class StoryService {
  selected: Story;
  stories: Story[];

  constructor(private books: BookService,
              private children: ChildrenService,
              private http: StorybookHttp) { }
  
  /**
   * @method      get
   * Description  Retrieves a customized story
   * Parameters   story_id: number
   * Returns      Observable<Story>
   */
  get( story ): Observable<any> {
    return this.http.get( API_ROOT + "/stories/" + story.story_id );
  }
  
  /**
   * @method      get
   * Description  Retrieves a customized story
   * Parameters   story_id: number
   * Returns      Observable<Story>
   */
  getMyStories( child, filters ): Observable<any> {
    return this.http.get( API_ROOT + "/stories?child_id=" + child.child_id );
  }


  /**
   * @method      save
   * Description  Saves customizations to a new story
   * Parameters   None
   * Returns      Observable<Story>
   */
  save( book_id: number, parameters: BookParameter[] ): Observable<any> {
    let payload = { child_id: this.children.selected.child_id,
                    settings: null };
    payload.settings = new Array();
    for( let i = 0; i < parameters.length; i++ ) {
      payload.settings.push( { name: parameters[i].name, value: parameters[i].value } );
    }
    return this.http.post(
        API_ROOT + "/books/" + book_id,
        payload
    );
  }
}
