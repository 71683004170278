import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Child } from './child';
import { StorybookHttp, API_ROOT } from './restapi';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ChildrenService {
  children: Child[];
  selected: Child;

  constructor(private http: StorybookHttp,
              private user: UserService) { }
  
  /**
   * @method      getChildren()
   * Description  Retrieves all children associated with the current user
   * Parameters   None
   * Returns      Observable<Child[]>
   */
  getChildren(): Observable<any> {
    return this.http.get(API_ROOT + "/children");
  }

  /**
   * @method      getChild()
   * Description  Retrieves a child given a specific ID
   * Parameters   child_id
   * Returns      Child
   */
  getChild( child_id ) {
    for(let i = 0; i < this.children.length; i++) {
      if( this.children[i].child_id == child_id ) {
        return this.children[i];
      }
    }
    return null;
  }
  
  setChild( child ) {
    this.selected = child;
  }
  
  add( child ) {
    return this.http.post(API_ROOT + "/children", child);
  }
}
