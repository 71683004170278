import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BookService } from './book.service';
import { ChildrenService } from './children.service';
import { StoryService } from './story.service';
import { UserService } from './user.service';
import { StorybookHttp } from './restapi';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { ChildComponent } from './child/child.component';
import { BookListComponent } from './book-list/book-list.component';
import { StoryListComponent } from './story-list/story-list.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { CustomizeStoryComponent } from './customize-story/customize-story.component';
import { ReadAndRateComponent } from './read-and-rate/read-and-rate.component';
import { AppRoutingModule } from './app-routing.module';
import { ChildrenComponent } from './children/children.component';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { BookComponent } from './book/book.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

@NgModule({
  declarations: [
    AppComponent,
    ChildComponent,
    BookListComponent,
    StoryListComponent,
    RegisterComponent,
    LoginComponent,
    CustomizeStoryComponent,
    ReadAndRateComponent,
    ChildrenComponent,
    SanitizeHtmlPipe,
    BookComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    ContactUsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    BookService,
    ChildrenService,
    StoryService,
    UserService,
    StorybookHttp
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  
}
