import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Book } from '../book';
import { BookService } from '../book.service';
import { ChildrenService } from '../children.service';
import { UserService } from '../user.service';
import { THEME } from '../config';

@Component({
  selector: 'app-book-list',
  templateUrl: './book-list.component.html',
  styleUrls: ['./book-list.component.css']
})
export class BookListComponent implements OnInit {
  theme: string = THEME;
  location: Location;
  book_list: Book[] = [];

  constructor(private books: BookService,
              private children: ChildrenService,
              private route: ActivatedRoute,
              private router: Router,
              private user: UserService) { }

  ngOnInit() {
    let url = this.route.snapshot.url[0].path;
    if( url == 'trial' ) {
     // console.log(this.children.selected);
      this.books.getTrialBooks( this.children.selected ).subscribe( res => this.book_list = res.books );
    }
    else if( url == 'my-stories' ) {
      this.books.getMyBooks( this.children.selected, '' ).subscribe( res => this.book_list = res.books );
    }
    else if( url == 'explore' ) {
      this.books.getAllBooks( this.children.selected, '' ).subscribe( res => this.book_list = res.books );
    }
  }
  
  tryIt( book: Book ) {
    this.books.select(book);
    this.router.navigate( ['/register' ] );
  }
  
  buyIt( book: Book ) {
    this.books.select(book);
    this.router.navigate( ['/customize' ] );
  }
  
  viewDetails( book: Book ) {
    this.books.select(book);
    this.router.navigate( ['/books', book.book_id] );
  }
  
  search() {
    
  }

}
