import { Component, OnInit } from '@angular/core';
import { Child } from '../child';
import { Router } from '@angular/router';
import { StoryService } from '../story.service';
import { ChildrenService } from '../children.service';
import { UserService } from '../user.service';
import { THEME } from '../config';
import { NotificationService } from '../notification.service';


@Component({
  selector: 'app-child',
  templateUrl: './child.component.html',
  styleUrls: ['./child.component.css']
})
export class ChildComponent implements OnInit {
  theme: string = THEME;
  child: Child = new Child();

  constructor(private router: Router,
              private stories: StoryService,
              private children: ChildrenService,
              private user: UserService,
              private notifications: NotificationService) { }

  ngOnInit() {
    this.child.gender = 'boy';
  }

  next() {
    if(this.child.name.length == 0) {
      this.notifications.add("No name error", "Please give a name for your child");
      return;
    }
    this.children.setChild( this.child );
    if( this.user.loggedIn() ) {
      this.router.navigate(['/bookstore'])
    }
    else {
      this.router.navigate(['/trial'])
    }
  }
  
  routeToChildren() {
    if(this.user.initial_user_check_complete && this.user.details.user_id) {
      this.router.navigate(['/children']);
    }
    return false;
  }

}
