import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../user';
import { UserService } from '../user.service';
import { BookService } from '../book.service';
import { StoryService } from '../story.service';
import { ChildrenService } from '../children.service';
import { THEME } from '../config';

const NAME_ERROR_STR     = "Please enter your name.";
const EMAIL_ERROR_STR    = "Please enter a valid email address.";
const PASSWORD_ERROR_STR = "Must be longer than 8 characters and include a digit, lowercase letter, uppercase letter and a symbol.";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  theme: string = THEME;
  name: string = '';
  email: string = '';
  password: string = '';
  name_error: string = '';
  email_error: string = '';
  password_error: string = '';

  constructor(private user: UserService,
              private books: BookService,
              private stories: StoryService,
              private children: ChildrenService,
              private router: Router ) { }

  ngOnInit() {
  }
  
  isValidEmailAddress() {
    return this.email.indexOf('@') > 0 || this.email.indexOf('@') < this.email.length - 1;
  }
  
  isValidPassword() {
    let big_letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let small_letters = 'abcdefghijklmnopqrstuvwxyz';
    let digits = '0123456789';
    let symbols = '\\|`¬,./<>?;\'#:@~[]{}-=_+!"£$%^&*()';
    let error = '';

    let i = 0;
    let found = false;
    for(i = big_letters.length -1; i >= 0 && !found; i-- ) {
      found = this.password.indexOf(big_letters[i]) >= 0;
    }
    if( !found ) return false;

    found = false;
    for(let i = small_letters.length -1; i >= 0 && !found; i-- ) {
      found = this.password.indexOf(small_letters[i]) >= 0;
    }
    if( !found ) return false;

    found = false;
    for(let i = digits.length -1; i >= 0 && !found; i-- ) {
      found = this.password.indexOf(digits[i]) >= 0;
    }
    /* 2018-11-17 Requirement for symbol in the password has been dropped
    if( !found ) return false;

    found = false;
    for(let i = symbols.length -1; i >= 0 && !found; i-- ) {
      found = this.password.indexOf(symbols[i]) >= 0;
    }
    */
    return found;
  }
  
  checkFormIsValid() {
    let result = true;
    if( this.name.length == 0 ) {
      this.name_error = NAME_ERROR_STR;
      result = false;
    }
    else {
      this.name_error = '';
    }
    if( !this.isValidEmailAddress() ) {
      this.email_error = 'Please enter a valid email address.';
      result = false;
    }
    else {
      this.email_error = '';
    }
    if( !this.isValidPassword() ) {
      this.password_error = PASSWORD_ERROR_STR;
      result = false;
    }
    else {
      this.password_error = '';
    }
    
    return result;
  }
  
  register() {
    if(this.checkFormIsValid()) {
      this.user.register(this.name, this.email, this.password, this.children.selected)
        .subscribe( res => {
                      this.user.details = res.users[0];
                      this.children.selected = res.children[0];
                      
                      if( this.books.selected.parameters.length ) {
                        this.stories.save( this.books.selected.book_id, this.books.selected.parameters )
                          .subscribe( res => {  this.stories.selected = res.stories[0];
                                                this.router.navigate( [ '/customize' ] ); }
                        );
                      }
                      else {
                        this.stories.save( this.books.selected.book_id, [] )
                          .subscribe( res => {  this.stories.selected = res.stories[0];
                                                this.router.navigate( [ '/storytime' ] ); } );
                      }
                    } );
    }
  }

}
