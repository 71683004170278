import { Component } from '@angular/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { UserService } from './user.service';
import { ChildrenService } from './children.service';
import { LoginComponent } from './login/login.component';
import { NotificationService } from './notification.service';
import { THEME } from './config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  theme: string = THEME;
  title = 'app';
  outlet_component = null;
  menu_active: boolean = false;
  
  constructor(private user: UserService,
              private children: ChildrenService,
              private notifications: NotificationService,
              private route: ActivatedRoute,
              private router: Router) {
  }
  
  loginPage(): boolean {
    return (this.outlet_component instanceof LoginComponent);
  }
  
  newRoute(event: any) {
    this.outlet_component = event;
  }
  
  logout() {
    this.activateDraw();
    this.user.logout().subscribe( res => this.router.navigate( ['/'] ) );
  }
  
  clearNotifications() {
    this.notifications.clear();
  }
  
  activateDraw() {
    this.menu_active = !this.menu_active;
  }
  
}
