import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Book } from '../book';
import { BookService } from '../book.service';
import { UserService } from '../user.service';
import { THEME } from '../config';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.css']
})
export class BookComponent implements OnInit {
  theme: string = THEME;
  book: Book;

  constructor(private books: BookService,
              private user: UserService,
              private router: Router) { }

  ngOnInit() {
    this.book = this.books.selected;
  }

  tryIt( book: Book ) {
    this.router.navigate( ['/register' ] );
  }
  
  buyIt( book: Book ) {
    this.router.navigate( ['/customize' ] );
  }
}
