import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { master_routes } from './route.settings';


@NgModule({
  imports: [ RouterModule.forRoot(master_routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
