/*
 * @class       BookService
 * Description  Performs asynchronous GET requests to the /books ReSTAPI
 *              to retrieve lists of books.
 */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Book } from './book';
import { Child } from './child';
import { StorybookHttp, API_ROOT } from './restapi';

@Injectable({
  providedIn: 'root'
})
export class BookService {
  selected: Book;

  constructor(private http: StorybookHttp) { }
  
  /**
   * @method      getTrialBooks()
   * Description  Rertrieves the available trial books.
   * Parameters   None
   * Returns      Observable<Books[]>
   */
  getTrialBooks( child: Child ): Observable<any> {
    return this.http.get(API_ROOT + "/books?child_name=" + child.name + "&gender=" + child.gender);
  }
  
  /**
   * @method      getLibraryBooks()
   * Description  Retrieves books from the users library.
   * Parameters   filter: string
   * Returns      Observable<Book[]>
   */
  getMyBooks( child: Child, filter: string ): Observable<any> {
    return this.http.get(API_ROOT + "/stories");
  }
  
  /**
   * @method      getBookstore()
   * Description  Retrieves all books from the bookstoregetBookstore
   * Parameters   filter: string
   * Returns      Observable<Book[]>
   */
  getAllBooks( child: Child, filter: string ): Observable<any> {
    return this.http.get(API_ROOT + "/books?child_name=" + child.name + "&gender=" + child.gender);

  }
  
  select(book: Book) {
    this.selected = book;
    this.selected.parameters = book.parameters.slice();
  }

}
