import { ActivatedRoute, Router, Routes } from '@angular/router';
import { ChildComponent } from './child/child.component';
import { BookListComponent } from './book-list/book-list.component';
import { StoryListComponent } from './story-list/story-list.component';
import { RegisterComponent } from './register/register.component';
import { CustomizeStoryComponent } from './customize-story/customize-story.component';
import { ReadAndRateComponent } from './read-and-rate/read-and-rate.component';
import { ChildrenComponent } from './children/children.component';
import { BookComponent } from './book/book.component';
import { LoginComponent } from './login/login.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

export const master_routes: Routes = [
{ path: 'children', component: ChildrenComponent },
{ path: 'login', component: LoginComponent },
{ path: 'storytime', component: ReadAndRateComponent },
{ path: 'customize', component: CustomizeStoryComponent },
{ path: 'register', component: RegisterComponent },
{ path: 'trial', component: BookListComponent },
{ path: 'my-stories', component: StoryListComponent },
{ path: 'explore', component: BookListComponent },
{ path: 'books/:id', component: BookComponent },
{ path: 'cookie-policy', component: CookiePolicyComponent },
{ path: 'privacy-policy', component: PrivacyPolicyComponent },
{ path: 'contact-us', component: ContactUsComponent },
{ path: '', component: ChildComponent }
];