import { Component, OnInit } from '@angular/core';
import { Book, Story } from '../book';
import { BookService } from '../book.service';
import { StoryService } from '../story.service';
import { Router } from '@angular/router';
import { THEME } from '../config';

@Component({
  selector: 'app-customize-story',
  templateUrl: './customize-story.component.html',
  styleUrls: ['./customize-story.component.css']
})
export class CustomizeStoryComponent implements OnInit {
  theme: string = THEME;

  constructor(private books: BookService,
              private stories: StoryService,
              private router: Router) { }

  ngOnInit() {
  }
  
  saveStory() {
    this.stories.save( this.books.selected.book_id, this.books.selected.parameters )
      .subscribe( res => {
          this.stories.selected = res.stories[0];
          this.router.navigate( [ '/storytime' ] ) 
      });
  }
  
}
