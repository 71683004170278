import { Component, OnInit } from '@angular/core';
import { Book, Story } from '../book';
import { BookService } from '../book.service';
import { StoryService } from '../story.service';
import { ChildrenService } from '../children.service';
import { THEME } from '../config';

declare var $book: any;

@Component({
  selector: 'app-read-and-rate',
  templateUrl: './read-and-rate.component.html',
  styleUrls: ['./read-and-rate.component.css']
})
export class ReadAndRateComponent implements OnInit {
  theme: string = THEME;
  story: Story = null;

  constructor(private books: BookService,
              private stories: StoryService,
              private children: ChildrenService) {
  }

  ngOnInit() {
    this.story = this.stories.selected;
    $book.go(this.story.story + '<p class="theend">the end</p>', 'story', 'illustration');
  }
  
  showPreviousPageButton(): boolean {
    let result = !$book.sof();
    return result;
  }
  
  showNextPageButton(): boolean {
    let result = !$book.eof();
    return result;
  }

  previousPage() {
    $book.previousPage();
    
  }
  
  nextPage() {
    $book.nextPage();
  }
  
  repaginate() {
    $book.paginate();
  }
}
