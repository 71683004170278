import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Story } from '../book';
import { StoryService } from '../story.service';
import { ChildrenService } from '../children.service';
import { UserService } from '../user.service';
import { THEME } from '../config';

@Component({
  selector: 'app-story-list',
  templateUrl: './story-list.component.html',
  styleUrls: ['./story-list.component.css']
})
export class StoryListComponent implements OnInit {
  theme: string = THEME;
  location: Location;
  story_list: Story[] = [];

  constructor(private stories: StoryService,
              private children: ChildrenService,
              private route: ActivatedRoute,
              private router: Router,
              private user: UserService) { }

  ngOnInit() {
    this.stories.getMyStories( this.children.selected, '' ).subscribe( res => this.story_list = res.stories );
  }
  
  readStory( story: Story ) {
    this.stories.selected = story;
    this.router.navigate( ['/storytime'] );
  }
  
  search() {
    
  }

}
