import { Injectable } from '@angular/core';

class Notification {
  title: string = '';
  description: string = '';
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notifications: Notification[];

  constructor() { this.clear(); }
  
  ngOnInit() {
    
  }
  
  add(title, description) {
    this.notifications.push( {title: title, description: description } );
  }
  
  clear() {
    this.notifications = new Array<Notification>();
  }
  
  get() {
    return this.notifications;
  }
  
  count() {
    return this.notifications.length;
  }
}
