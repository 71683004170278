export class BookParameter {
  name   : string = '';
  caption: string = '';
  meta   : Object;
  value  : string = '';
};

export class Book {
  book_id  : number = 0;
  thumbnail: string = '';
  title    : string = '';
  author   : string = '';
  synopsis : string = '';
  rating   : string = '';
  duration   : string = '';
  categories: string[] = new Array<string>();
  stereotype: string;
  parameters: BookParameter[] = new Array<BookParameter>();
};

export class Story {
  story_id: number = 0;
  thumbnail: string = '';
  title    : string = '';
  author   : string = '';
  synopsis : string = '';
  rating   : string = '';
  duration : string = '';
  story    : string = '';
}